<template>
	<div class="withdrawal">
		<el-card class="box-card">
			<el-table :data="tableData" border style="width: 100%;margin-top: 30px;">
				<el-table-column prop="account" label="提现账号" align="center">
				</el-table-column>
				<el-table-column prop="" label="提现时间" align="center">
					<template slot-scope="{row}">{{ $func.parseTime(row.createtime)}}</template>
				</el-table-column>
				<el-table-column prop="total_fee" label="提现金额" align="center">
				</el-table-column>
			</el-table>
			<div class="" style="margin-top: 30px;text-align: center;">
				<el-pagination background layout="prev, pager, next" :page-size="size" :total="total"
					@current-change='change'>
				</el-pagination>
			</div>

		</el-card>
	</div>
</template>

<script>
	import {
		find_apply_list
	} from '../api/home.js'
	export default {
		data() {
			return {
				tableData: [],
				page: 1,
				total: 150,
				size: 15,
				user: {}
			}
		},
		created() {
			this.find_apply_list()
		},
		methods: {
			find_apply_list() {
				find_apply_list({
					page: this.page,
					user_id: JSON.parse(window.localStorage.getItem('user')).id
				}).then(res => {
					this.total = res.data.total
					this.tableData = res.data.data
				})
			},
			// 页码值变化
			change(e) {
				this.page = e
				this.find_apply_list()
			}
		}
	}
</script>

<style>
</style>

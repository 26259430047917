import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Recovery from '../views/recovery.vue'
import Reserve from '../views/reserve.vue'
import Retrieve from '../views/retrieve.vue'
import User from '../views/user.vue'
import Personal from '../views/personal.vue'
import Order from '../views/order.vue'
import WithdrawList from '../views/withdraw_list.vue'
import Realname from '../views/realname.vue'
import Index from '../views/index.vue'
// import Login from '../views/login.vue'
import Details from '../views/details.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		component: Index,
		redirect: '/home',
		children: [{
				path: '/home',
				component: Home
			},
			{
				path: '/recovery',
				component: Recovery
			},
			{
				path: '/retrieve',
				component: Retrieve
			},
			{
				path: '/reserve',
				component: Reserve
			},
			// {
			// 	path: '/login',
			// 	component: Login,
			// }
		]
	},
	{
		path: '/user',
		component: User,
		redirect: '/personal',
		children: [{
				path: '/personal',
				component: Personal
			},
			{
				path: '/order',
				component: Order
			},
			{
				path: '/withdrawlist',
				component: WithdrawList
			},
			{
				path: '/realname',
				component: Realname
			},
            {
                path: '/details',
                component: Details
            }
		]
	},

	// {
	//   path: '/about',
	//   name: 'About',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
]

const router = new VueRouter({
	routes
})

export default router

<template>
	<div class="personal">
		<!-- 用户信息 -->
		<el-card class="">
			<div class="avatar">
				<div class="">
					<span>账号:</span>
					<span style="color: #409EFF;margin-left: 10px;">{{user.mobile}}</span>
				</div>
				<router-link :to="{path:'/realname'}" class="realname">
					实名认证
				</router-link>
			</div>
			<div class="withdrawal">
				<div class="">
					<span class="color">总收入金额：</span>
					<span>￥{{user.deposit}}</span>
				</div>
				<div class="">
					<span class="color">可提现金额：</span>
					<span style="margin-right: 10px;">￥{{user.balance}}</span>
					<!-- <router-link :to="{path:'/user'}" class="realname">
						提现
					</router-link> -->
				</div>
			</div>
		</el-card>
		<!-- 订单信息 -->
		<el-card class="box-card">
			<div class="">
				<div class="">
					<span class="order_tit">|</span>
					<span>提现</span>
				</div>
				<div class="withdraw">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
						<el-form-item label="提现金额" prop="total_fee">
							<el-input type="text" v-model="ruleForm.total_fee" placeholder='请输入提现金额'></el-input>
						</el-form-item>
						<el-form-item label="提现账号" prop="account">
							<el-input type="text" v-model="ruleForm.account" placeholder='请输入支付宝账号' :disabled="disabled"></el-input>
						</el-form-item>
						<el-button type="primary" class="sbumit" @click="submitForm('ruleForm')">提现</el-button>
					</el-form>
                    <div>
                        {{setting.site_withdraw_desc}}
                    </div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		add_apply,
		find_member,
        find_setting
	} from '../api/home.js'
	export default {
		data() {
			return {
				ruleForm: {
					total_fee: '',
					account: '',
				},
				rules: {
					total_fee: [{
						required: true,
						message: '请输入提现金额',
						trigger: 'blur'
					}],
					account: [{
						required: true,
						message: '请输入支付宝账号',
						trigger: 'blur'
					}],
				},
				user: {},
                setting:{},
                disabled: false
			}
		},
		created() {
			this.user = JSON.parse(window.localStorage.getItem('user'))
			this.ruleForm.account = this.user.aliaccount
            if (this.user.aliaccount) {
                this.disabled = true
            }
			this.find_member()
            this.find_setting()
		},
		methods: {
            // 查询系统信息
            find_setting() {
                find_setting().then(res => {
                    this.setting = res.data
                })
            },
			// 提交事件
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						add_apply({
							user_id: this.user.id,
							total_fee: this.ruleForm.total_fee,
							account: this.ruleForm.account
						}).then(res => {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.ruleForm = {
								total_fee: '',
								account: ''
							}
							this.find_member()
						}).catch(err => {
							this.$message({
								message: err.msg,
								type: 'warning'
							});
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			find_member() {
				find_member({
					user_id: this.user.id,
				}).then(res => {
					window.localStorage.setItem('user', JSON.stringify(res.data))
					this.user = res.data
				})
			},
            
		}
	}
</script>

<style scoped="scoped">
	.avatar {
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: space-between;
	}

	.avatar_box {
		margin-right: 20px;
	}

	.withdrawal {
		display: flex;
		justify-content: space-between;
		background-color: #F5F5F5;
		padding: 15px 60px;
		margin: 30px 0 0 0;
		font-size: 14px;
		font-weight: 600;
	}

	.color {
		color: #8F8F8F;
		font-weight: 400;
	}

	.box-card {
		margin: 30px 0;
		font-size: 14px;
	}

	.order_tit {
		color: #009DFC;
		font-weight: 600;
		margin-right: 10px;
	}

	.realname {
		background-color: #009DFC;
		padding: 4px 16px;
		border-radius: 50px;
		color: #FFFFFF;
		font-size: 14px;
	}

	.withdraw {
		width: 400px;
		margin: 60px auto;
		text-align: center;
	}

	.sbumit {
		width: 30%;
		margin: 30px 0;
	}
</style>

<template>
	<div class="realname">
		<el-card class="box-card">
			<h3>实名认证</h3>
			<div class="box">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
					<el-form-item label="姓名" prop="username">
						<el-input type="text" v-model="ruleForm.username" placeholder='请输入姓名'></el-input>
					</el-form-item>
					<el-form-item label="身份证" prop="idcard">
						<el-input type="text" v-model="ruleForm.idcard" placeholder='请输入身份证号'></el-input>
					</el-form-item>
					<div class="imgs">
						<div class="">
							<el-upload class="avatar-uploader"
								action="https://dz284.aulod.com/api/wxapp/public/upload_asset" :show-file-list="false"
								:on-success="handleAvatarSuccess1">
								<!-- <img  :src="imageUrl_1" width="100%" height="100%" class="avatar"> -->
								<img  width="100%" :src="src_1" alt="" height="100%" class="avatar">
							</el-upload>
						</div>
						<div class="">
							<el-upload class="avatar-uploader"
								action="https://dz284.aulod.com/api/wxapp/public/upload_asset" :show-file-list="false"
								:on-success="handleAvatarSuccess2">
								<!-- <img v-if="imageUrl_2" :src="imageUrl_2" width="100%" height="100%" class="avatar"> -->
								<img  width="100%" :src="src_2" alt="" height="100%" class="avatar">
							</el-upload>
						</div>
					</div>
					<el-button type="primary" class="sbumit" @click="submitForm('ruleForm')">确定</el-button>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		update_member
	} from '../api/home.js'
	export default {
		data() {
			return {
				src_1: require('@/assets/user/card-1.png'),
				src_2: require('@/assets/user/card-2.png'),
				imageUrl_1: '',
				imageUrl_2: '',
				ruleForm: {
					username: '',
					idcard: '',
				},
				rules: {
					username: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					idcard: [{
						required: true,
						message: '请输入身份证号',
						min: 18,
						max: 18,
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			// 监听上传时间
			handleAvatarSuccess1(res, file) {
				this.src_1 = res.data.url;
                this.imageUrl_1 = res.data.filepath;
			},
			// 监听上传时间
			handleAvatarSuccess2(res, file) {
				this.src_2 = res.data.url;
				this.imageUrl_2 = res.data.filepath;
			},
			// 提交事件
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (!this.imageUrl_1) return this.$message({
							message: '请上传身份证正面照片',
							type: 'warning'
						})
						if (!this.imageUrl_2) return this.$message({
							message: '请上传身份证反面照片',
							type: 'warning'
						})
						update_member({
							id: JSON.parse(window.localStorage.getItem('user')).id,
							username: this.ruleForm.username,
							idcard: this.ruleForm.idcard,
							idcard_front: this.imageUrl_1,
							idcard_back: this.imageUrl_2
						}).then(res => {
							this.$message({
								message: res.msg,
								type: 'success'
							})
						}).catch(err=>{
                            this.$message({
                            	message: err.msg,
                            	type: 'error'
                            })
                        })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style scoped>
	.box {
		width: 600px;
		margin: 130px auto;
		align-items: center;
		text-align: center;
	}

	.box .imgs {
		display: flex;
		justify-content: space-between;
		margin: 80px 0;
	}

	.avatar-uploader {
		width: 280px !important;
		height: 220px !important;
	}

	.avatar {
		width: 280px;
		height: 200px;
		display: block;
	}

	.sbumit {
		width: 60%;
	}
</style>

<template>
    <div class="" style="padding: 100px;background-color: #fff;">
        <div style="font-size: 20px;font-weight: bold;">
            订单详情
        </div>
        <el-table :data="order.card_list" border style="width: 100%;margin-top: 30px;" @row-click="row_click">
            <el-table-column prop="cardno" label="卡号" align="center">
            </el-table-column>
            <el-table-column prop="password" label="密码" align="center">
            </el-table-column>
        </el-table>
        <div style="margin-top: 30px;" >
            面额: {{order.real_price}}
        </div>
        <div style="margin-top: 30px;" >
            总金额: {{order.need_price}}
        </div>
        <div style="margin-top: 30px;" v-if="order.reason">
            拒绝原因: {{order.reason}}
        </div>
    </div>
</template>

<script>
   import {
       find_order
   } from '../api/home.js'
   export default {
       data() {
           return {
               order:{},
               id :0
           }
       },
       created() {
           // if (this.$route.query.id) {
           //     console.log(this.$route.query.id)
           // }
           this.find_order(this.$route.query.id)
       },
       methods: {
          
           find_order(id) {
               find_order({
                   order_id:id
               }).then(res => {
                   this.order = res.data
               })
           },
        
       }
   }
</script>

<style>
</style>
import axios from 'axios'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
var user = JSON.parse(window.localStorage.getItem('user'))
axios.defaults.headers['userid'] = user ? user.id : ''


// 配置请求根路径
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: 'https://dz284.aulod.com',
    // https://xf1001.com.cn
    // http://mx1001.cn
    // 超时
    timeout: 1000,
})


service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code == 1) {
            return res;
            // 验证TOKEN，如失效则重新登陆
        } else {
            return Promise.reject(res);
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service

import request from '../utils/request.js'

// 获取导航信息
export function gettabs(data) {
	return request({
		url: '/api/wxapp/public/find_navs',
		method: 'post',
		data: data
	})
}
// 获取导航信息
export function getnews(data) {
	return request({
		url: '/api/wxapp/public/find_news',
		method: 'post',
		data: data
	})
}
// 获取分类列表
export function category_list(data) {
	return request({
		url: '/api/wxapp/card/find_goods_category_list',
		method: 'post',
		data: data
	})
}
// 获取分类列表
export function goods_list(data) {
	return request({
		url: '/api/wxapp/card/find_goods_list',
		method: 'post',
		data: data
	})
}
// 获取幻灯片
export function find_slide(data) {
	return request({
		url: '/api/wxapp/public/find_slide',
		method: 'post',
		data: data
	})
}
// 提交预约信息
export function add_appointment(data) {
	return request({
		url: '/api/wxapp/card/add_appointment',
		method: 'post',
		data: data
	})
}
// 用户注册
export function member_register(data) {
	return request({
		url: '/api/wxapp/card/member_register',
		method: 'post',
		data: data
	})
}
// 用户登录
export function member_login(data) {
	return request({
		url: '/api/wxapp/card/member_login',
		method: 'post',
		data: data
	})
}
// 重置密码
export function reset_password(data) {
	return request({
		url: '/api/wxapp/card/reset_password',
		method: 'post',
		data: data
	})
}
// 提交订单
export function add_order(data) {
	return request({
		url: '/api/wxapp/card/add_order',
		method: 'post',
		data: data
	})
}
// 申请提现
export function add_apply(data) {
	return request({
		url: '/api/wxapp/card/add_apply',
		method: 'post',
		data: data
	})
}
// 提现记录
export function find_apply_list(data) {
	return request({
		url: '/api/wxapp/card/find_apply_list',
		method: 'post',
		data: data
	})
}
// 我的订单
export function find_orderlist(data) {
	return request({
		url: '/api/wxapp/card/find_orderlist',
		method: 'post',
		data: data
	})
}	
// 我的订单详情
export function find_order(data) {
	return request({
		url: '/api/wxapp/card/find_order',
		method: 'post',
		data: data
	})
}	
// 实名认证
export function update_member(data) {
	return request({
		url: '/api/wxapp/card/update_member',
		method: 'post',
		data: data
	})
}
// 省市区
export function get_area(data) {
	return request({
		url: '/api/wxapp/public/get_area',
		method: 'post',
		data: data
	})
}
// 省市区
export function find_newsdetail(data) {
	return request({
		url: '/api/wxapp/public/find_newsdetail',
		method: 'post',
		data: data
	})
}
// 获取短信
export function send_sms(data) {
	return request({
		url: '/api/wxapp/public/send_sms',
		method: 'post',
		data: data
	})
}
// 获取系统配置信息
export function find_setting(data) {
	return request({
		url: '/api/wxapp/public/find_setting',
		method: 'post',
		data: data
	})
}
// 查询会员信息
export function find_member(data) {
	return request({
		url: '/api/wxapp/card/find_member',
		method: 'post',
		data: data
	})
}


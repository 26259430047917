<template>
	<!-- <div class="user"> -->
	<el-container class="hei">
		<!-- 左侧区域 -->
		<el-aside width="260px">
			<el-menu :default-active="activeIndex" background-color="#2998FD" active-text-color='#F97C58'
				text-color="#fff" @select="handleSelect" class="el-menu-demo lan" mode="vertical" router>
				<div style="margin: 50px 0;text-align: center;">
					<img src="../assets/home/logo.png" style="width: 120px; height: 120px;">
				</div>
				<el-menu-item index="personal">
					<template slot="title">
						<i class="el-icon-user"></i>
						<span>个人中心</span>
					</template>
				</el-menu-item>
				<el-menu-item index="order">
					<template slot="title">
						<i class="el-icon-notebook-2"></i>
						<span>我的订单</span>
					</template>
				</el-menu-item>
				<el-menu-item index="withdrawlist">
					<template slot="title">
						<i class="el-icon-bank-card"></i>
						<span>提现记录</span>
					</template>
				</el-menu-item>
				<el-menu-item index="realname">
					<template slot="title">
						<i class="el-icon-s-custom"></i>
						<span>实名认证</span>
					</template>
				</el-menu-item>
			</el-menu>
		</el-aside>
		<!-- 右侧内容 -->
		<el-container>
			<!-- 头部区域 -->
			<el-header>
				<el-menu class="el-menu-demo" mode="horizontal" router>
					<el-menu-item index="home">首页</el-menu-item>
					<el-menu-item index="recovery">加油卡</el-menu-item>
					<el-menu-item index="recovery">话费卡</el-menu-item>
					<el-menu-item index="recovery">电商卡</el-menu-item>
					<el-menu-item index="recovery">旅游卡</el-menu-item>
					<el-menu-item index="recovery">商超卡</el-menu-item>
					<el-menu-item index="recovery">餐饮卡</el-menu-item>
					<el-menu-item index="retrieve">手机回收</el-menu-item>
					<el-menu-item index="reserve">预约上门</el-menu-item>
					<div class="avatar">
						{{iphone}}
					</div>
				</el-menu>
			</el-header>
			<!-- 主体区域 -->
			<el-main>
				<!-- 路由占位符 -->
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
	<!-- </div> -->
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: 'personal',
				iphone: "",
			}
		},
		// 页面刷新 把当前的路由地址让选中
		created() {
			const url = this.$route.path.substring(this.$route.path.lastIndexOf("/") + 1);
			this.activeIndex = url;
			this.iphone = JSON.parse(window.localStorage.getItem('user')).mobile
		},
		// 监听路由变化
		watch: {
			$route(to, from) {
				const url = this.$route.path.substring(this.$route.path.lastIndexOf("/") + 1);
				this.activeIndex = url;
			}
		},
		methods: {
			handleSelect(key, keyPath) {
				// this.activeIndex = key;
			}
		}
	}
</script>

<style scoped="scoped">
	body,
	.el-main {
		background-color: #F5F5F5;
	}

	.iphone {
		color: #009dfc;
		font-size: 14px;
		margin-left: 6px;
	}

	.el-menu-item {
		text-align: center;
	}

	.el-menu-item i {
		color: #fff;
	}

	.el-menu--horizontal {
		padding-left: 42% !important;
	}

	.el-header {
		padding: 0 !important;
	}

	.el-menu {
		border: 0 !important;
	}

	.avatar {
		text-align: center;
		font-size: 14px;
		padding: 20px 0;
		color: #409EFF;
	}

	.hei {
		height: 100%;
	}

	.lan {
		height: 100% !important;
	}
</style>

<template>
	<div class="retrieve">
		<div class="body">
			<ul class="body_list">
				<li class="box" v-for="(item,index) in mobile_list" :key='index' @click="clickBtn(item,index)">
					<!-- <img :src="item.image" style="width: 130px;height: 100px;"> -->
					<el-image :src="item.image" fit="contain" style="width: 130px;height: 100px;"></el-image>
					<div class="">
						<div class="box_title">
							{{item.title}}
						</div>
						<div class="box_body">
							{{item.description}}
						</div>
						<div class="box_price">
							￥{{item.content}}
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		find_slide
	} from '../api/home.js'
	export default {
		data() {
			return {
				mobile_list: []
			}
		},
		created() {
			this.find_slide()
		},
		methods: {
			// 获取手机分类列表
			find_slide() {
				find_slide({
					slide_id: 4
				}).then(res => {
					this.mobile_list = res.data
				})
			},
			// 
			clickBtn(item, index) {
				console.log(item, index)
			}
		}
	}
</script>

<style scoped="scoped">
	.retrieve {
		height: 100%;
		background-color: #F5F5F5;
	}

	.body {
		width: 1200px;
		margin: 0 auto;
	}

	.body_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.box {
		/* display: flex;
		flex-direction: column;
		justify-content: space-between; */
		width: 330px;
		height: 460px;
		padding: 70px 0;
		flex-shrink: 0;
		background-color: #FFFFFF !important;
		text-align: center;
		margin-top: 50px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
	}

	.box .box_title {
		margin-top: 50px;
		font-size: 28px;
	}

	.box .box_body {
		margin-top: 20px;
		font-size: 24px;
		color: #838383;
	}

	.box .box_price {
		margin-top: 20px;
		font-size: 24px;
		color: #EC3737;
	}
</style>

<template>
	<div class="index">
		<el-image style="width: 100%; height: 520px;min-width: 1400px;" :src="url">
		</el-image>
		<div class="body">
			<!-- 回收卡分类 -->
			<div>
				<div class="classify">
					回收卡分类
				</div>
				<ul class="classify_1">
					<li style="padding: 40px;" v-for="(item,index) in tabs_list" :key='index'
						@click="goRecovery(item.id)">
						<img :src="item.icon" class="img_1">
						<div id="" style="margin-top: 10px;">
							{{item.name}}
						</div>
					</li>
				</ul>
			</div>
			<!-- 热门回收 -->
			<div class="">
				<div class="classify">
					热门回收
				</div>
				<ul class="classify_1">
					<li style="padding: 40px;" v-for="(item,index) in hot_list" :key='index' @click="goRecovery(21)">
						<img :src="item.icon" class="img_1">
						<div id="" style="margin-top: 10px;">
							{{item.name}}
						</div>
					</li>
				</ul>
			</div>
			<!-- 常见回收 -->
			<div>
				<div class="classify">
					常见问题
				</div>
				<ul class="classify_2">
					<li style="width: 43%;margin-top: 30px;" v-for="(item,index) in news_list" :key='index'>
						<h3>{{item.title}}</h3>
						<p v-html="item.post"></p>
					</li>
				</ul>
			</div>
		</div>
        <div style="text-align: center;padding: 60px;"><a href="https://beian.miit.gov.cn/" target="_blank"> 沪ICP备2023006930号-1  </a></div>
	</div>
</template>

<script>
	import {
		gettabs,
		getnews
	} from '../api/home.js'
	import Bus from '../utils/bus.js'
	export default {
		data() {
			return {
				url: require('@/assets/home/banner.png'),
				tabs_list: [],
				hot_list: [],
				news_list: [],
			}
		},
		created() {
			this.gettabs()
			this.gethotlist()
			this.getnews()
		},
		methods: {
			// 获取问题列表
			getnews() {
				getnews({
					cate_id: 10
				}).then(res => {
					this.news_list = res.data.data
				})
			},
			// 获取商品分类
			gettabs() {
				gettabs().then(res => {
					this.tabs_list = res.data
				})
			},
			// 获取热门卡类列表
			gethotlist() {
				gettabs({
					nav_id: 25
				}).then(res => {
					this.hot_list = res.data
				})
			},
			// 跳转页面
			goRecovery(id) {
				let url = '';
				switch (Number(id)) {
					case 1:
						url = '/home';
						break;
					case 23:
						url = '/retrieve';
						break;
					case 24:
						url = '/reserve';
						break;
					default:
						url = '/recovery?id=' + id;
						break;
				}
				this.$store.commit('set_id', id)
				this.$router.push(url)
				Bus.$emit('key', id);
			}
		}
	}
</script>

<style scoped="scoped">
	.color {
		background-color: #0670F5;
	}

	.el-menu {
		border-bottom: 1px solid #0670F5 !important;
	}



	.body {
		width: 1200px;
		margin: 0 auto 60px;
		text-align: center;
	}

	.classify {
		font-size: 36px;
		margin: 70px 0;
	}

	.classify_1 {
		display: flex;
		flex-wrap: wrap;
	}


	.img {
		width: 80px;
		height: 80px;
		border-radius: 50px;
	}

	.img_1 {
		width: 138px;
		height: 95px;
	}

	.classify_2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		text-align: left;
	}

	p {
		margin-top: 10px;
		color: #AAAAAA;
		font-size: 14px;
	}
</style>

<template>
    <div class="order">
        <div class="">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">全部订单</el-menu-item>
                <el-menu-item index="2">等待验证</el-menu-item>
                <el-menu-item index="3">交易完成</el-menu-item>
                <el-menu-item index="4">交易失败</el-menu-item>
                <!-- <div style="float: right;padding: 10px 30px;">
					<span class="type" style="font-weight: 600;">卡种类型:</span>
					<el-select v-model="value" placeholder="全部类型">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div> -->
            </el-menu>
            <el-table :data="tableData" border style="width: 100%;margin-top: 30px;" @row-click="row_click">
                <el-table-column prop="ordersn" label="订单编号" align="center">
                </el-table-column>
                <el-table-column prop="createtime" label="提交时间" align="center">
                </el-table-column>
                <el-table-column prop="card_name" label="卡种类型" align="center">
                </el-table-column>
                <el-table-column prop="real_price" label="转让价格" align="center">
                </el-table-column>
                <el-table-column prop="card_total" label="条数" align="center">
                </el-table-column>
                <el-table-column prop="need_price" label="结算金额" align="center">
                </el-table-column>
                <el-table-column prop="status" label="订单状态" align="center">
                    <template slot-scope="d">
                        <div class="" v-if="d.row.status == 0">
                            等待验证
                        </div>
                        <div class="" style="color: #67C23A;" v-else-if="d.row.status == 1">
                            交易完成
                        </div>
                        <div class="" style="color: #F56C6C;" v-else='d.row.status == 2'>
                            交易失败
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="peges">
            <el-pagination layout="prev, pager, next" :page-size="size" :total="total" @current-change='change'>
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import {
        find_orderlist
    } from '../api/home.js'
    export default {
        data() {
            return {
                total: 150,
                activeIndex: '1',
                tableData: [],
                options: [],
                value: '',
                status: -1,
                page: 1,
                size: 15
            }
        },
        created() {
            this.find_orderlist()
        },
        methods: {
            // 
            row_click(e) {
                console.log(e)
                 this.$router.push('/details?id='+e.id)
            },
            find_orderlist() {
                find_orderlist({
                    page: this.page,
                    status: this.status,
                    user_id: JSON.parse(window.localStorage.getItem('user')).id
                }).then(res => {
                    this.total = res.data.total
                    this.tableData = res.data.data
                })
            },
            // 订单tabs切换
            handleSelect(key, keyPath) {
                this.activeIndex = key
                if (key == 1) {
                    this.status = -1
                } else if (key == 2) {
                    this.status = 0
                } else if (key == 3) {
                    this.status = 1
                } else {
                    this.status = 2
                }
                this.find_orderlist()
            },
            // 页码值变化
            change(e) {
                this.page = e
                this.find_orderlist()
            }
        }
    }
</script>

<style scoped="scoped">
    .type {
        margin-right: 10px;
        font-weight: 600;
    }

    .peges {
        margin: 30px 0;
        text-align: center;
    }
</style>

<template>
	<div class="reserve">
		<img src="../assets/reserve_banner.png" class="banner">
		<div class="body">
			<el-form label-position="left" label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="卡类" prop="card_id">
					<el-select v-model="ruleForm.card_id" placeholder="请选择卡类">
						<el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系电话" prop="mobile">
					<el-input v-model="ruleForm.mobile" placeholder='请输入联系电话'></el-input>
				</el-form-item>
				<el-form-item label="备用电话" prop="second_mobile">
					<el-input v-model="ruleForm.second_mobile" placeholder='请输入备用电话'></el-input>
				</el-form-item>
				<el-form-item label="所在区域" prop="region">
					<el-cascader v-model="ruleForm.region" :options="cascader" placeholder='请选择地区'
						:props="{ expandTrigger: 'hover', value:'name', label:'name' }" @change="change"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="address">
					<el-input v-model="ruleForm.address" placeholder='请输入具体地址'></el-input>
				</el-form-item>
				<el-form-item label="卡数量" prop="total">
					<el-input v-model="ruleForm.total" placeholder='请输入卡数量'></el-input>
				</el-form-item>
			</el-form>
			<div class="" style="text-align: center;">
				<el-button type="primary" @click="submitForm('ruleForm')">确认提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		add_appointment,
		category_list,
		get_area
	} from '../api/home.js'
	export default {
		data() {
			return {
				ruleForm: {
					card_id: '',
					mobile: '',
					second_mobile: '',
					region: [],
					address: '',
					total: ''
				},
				user_id: 0,
				province: '',
				city: '',
				area: '',
				rules: {
					card_id: [{
						required: true,
						message: '请选择卡种',
						trigger: 'change'
					}, ],
					mobile: [{
						required: true,
						message: '请填写联系电话',
						trigger: 'blur'
					}],
					second_mobile: [{
						required: true,
						message: '请填写备用电话',
						trigger: 'blur'
					}],
					region: [{
						required: true,
						message: '请选择地区',
						trigger: 'change'
					}],
					address: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					total: [{
						required: true,
						message: '请填写卡数量',
						trigger: 'blur'
					}]
				},
				options: [],
				value: '',
				cascader: []
			};
		},
		created() {
			this.getCards()
			this.get_area()
		},
		methods: {
			// 提交预约
			submitForm(ruleForm) {
				if (!window.localStorage.getItem('user')) return this.$message({
					message: '请先点击右上角登录',
					type: 'warning'
				});
				if (!JSON.parse(window.localStorage.getItem('user')).username) return this.$message({
					message: '请先去实名认证',
					type: 'warning'
				});
				this.$refs[ruleForm].validate((valid) => {
					if (valid) {
						add_appointment({
							user_id: JSON.parse(window.localStorage.getItem('user')).id,
							username: JSON.parse(window.localStorage.getItem('user')).username,
							mobile: this.ruleForm.mobile,
							second_mobile: this.ruleForm.second_mobile,
							card_id: this.ruleForm.card_id,
							province: this.province,
							city: this.city,
							area: this.area,
							address: this.ruleForm.address,
							total: this.ruleForm.total
						}).then(res => {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.ruleForm = {
								card_id: '',
								mobile: '',
								second_mobile: '',
								region: [],
								address: '',
								total: ''
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 选择地区
			change(e) {
				this.province = e[0]
				this.city = e[1]
				this.area = e[2]
			},
			// 获取省市区
			get_area() {
				get_area().then(res => {
					this.cascader = res.data
				})
			},
			// 获取卡类
			getCards() {
				category_list().then(res => {
					this.options = res.data
				})
			}
		}
	}
</script>

<style scoped="scoped">
	body {
		background-color: #F5F5F5;
	}

	.banner {
		width: 100%;
	}

	.el-select,
	.el-cascader {
		display: block !important;
	}

	.body {
		width: 1200px;
		background-color: #FFFFFF;
		margin: 30px auto;
		padding: 50px;
	}
</style>

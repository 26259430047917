<template>
    <div class="home">
        <el-container>
            <!-- 头部导航栏 -->
            <el-header>
                <!-- 头部导航栏 -->
                <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect"
                    background-color="#0670F5" text-color="#fff" active-text-color="#fff">
                    <span class="" style="margin: 0 30px 0 16%;float: left;">
                        <img src="@/assets/home/logo.png" style="height: 60px;width: 50px;">
                    </span>
                    <el-menu-item :index="item.id+''" v-for="(item,index) in tital_list" :key='index'>{{item.name}}
                    </el-menu-item>
                    <span class="loginbox">
                        <div class="login" @click="dialogVisible = true" v-if="!user">
                            点击登录
                        </div>
                        <div class="" v-else>
                            <router-link to="user" class="">
                                <el-button size="medium" round>{{user.mobile}}</el-button>
                            </router-link>
                            <el-button style="margin-left: 20px;" size="medium" type="warning" round @click='logOut'>
                                退出登录</el-button>
                        </div>
                    </span>
                </el-menu>
                <!-- 登录弹出框 -->
                <el-dialog :visible.sync="dialogVisible" width="400px" :before-close="handleClose" center
                    :modal="dialogVisible">
                    <div class="login_title">
                        {{login==0?'登录':(login==1?'注册':'重置密码')}}
                    </div>
                    <div class="login_body">
                        <!-- 注册 -->
                        <div class="" v-if="login==1">
                            <el-form :model="form" label-position="left">
                                <el-form-item>
                                    <el-input v-model="form.mobile" auto-complete="off" placeholder='请输入手机号'></el-input>
                                </el-form-item>
                                <el-form-item class="code">
                                    <el-input v-model="form.code" placeholder='请输入验证码'></el-input>
                                    <el-button type="primary" :disabled='isDisabled' @click="sendCode">{{buttonText}}
                                    </el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <!-- 登录 -->
                        <div class="" v-else-if="login == 0">
                            <el-form :model="forms" label-position="left">
                                <el-form-item>
                                    <el-input v-model="forms.mobile" auto-complete="off" placeholder='请输入手机号'>
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="code">
                                    <el-input placeholder="请输入密码" v-model="forms.password" show-password></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <!-- 修改密码 -->
                        <div class="" v-else-if="login == 2">
                            <el-form :model="pass" label-position="left">
                                <el-form-item>
                                    <el-input v-model="pass.mobile" auto-complete="off" placeholder='请输入手机号'></el-input>
                                </el-form-item>
                                <el-form-item class="code">
                                    <el-input v-model="pass.code" placeholder='请输入验证码'></el-input>
                                    <el-button type="primary" :disabled='isDisabled' @click="sendCode">{{buttonText}}
                                    </el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-input placeholder="请输入新密码" v-model="pass.password" show-password></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <!-- 登录注册显示 -->
                        <div class="" style="text-align: right;">
                            <div class="" style="text-align: right;margin-bottom: 10px;" v-if="login==0">
                                <span @click="loginbtn(2)">忘记密码?</span>
                            </div>
                            <span>{{login==1?'没有账号？':'已有账号？'}}</span>
                            <span style="color: #409EFF; margin-left: 10px;" @click="loginbtn(1)"
                                v-if="login==0">立即注册</span>
                            <span style="color: #409EFF; margin-left: 10px;" @click="loginbtn(0)"
                                v-else-if="login==1||login==2">立即登录</span>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="submit" v-if="login==0">立即登录</el-button>
                        <el-button type="primary" @click="register" v-else-if="login==1">立即注册</el-button>
                        <el-button type="primary" @click="reset" v-else>确认重置</el-button>
                    </span>
                </el-dialog>
            </el-header>
            <!-- 内容区域 -->
            <el-main>
                <!-- 路由占位符 -->
                <router-view></router-view>
                <div class="box">
                    <div class="kefu">
                        <div class="" style="font-size: 30px;">
                            <i class="el-icon-s-comment"></i>
                        </div>
                        <div class="">
                            在线咨询
                        </div>
                    </div>
                    <div class="box_left">
                        <div class="" style="width: 200px; height: 200px;">
                            <img :src="setting.site_logo+setting.site_kfqrcode" style="width: 100%;height: 100%;">
                        </div>
                        <div class="" style="text-align: center;margin-top: 8px; ">
                            扫码添加客服微信
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import {
        gettabs,
        member_login,
        send_sms,
        find_setting,
        member_register,
        reset_password
    } from '../api/home.js'
    import Bus from '../utils/bus.js'
    export default {
        data() {
            return {
                form: {
                    mobile: '',
                    code: '',
                    password: ''
                },
                forms: {
                    mobile: '',
                    password: ''
                },
                pass: {
                    mobile: '',
                    code: '',
                    password: ''
                },
                dialogVisible: false,
                activeIndex: "1",
                btnIndex: 0,
                tital_list: [{
                    name: '首页',
                    url: 'home',
                    id: '1'
                }],
                buttonText: '发送验证码',
                isDisabled: false, // 是否禁止点击发送验证码按钮
                flag: true,
                user: {},
                setting: {},
                login: 0
            };
        },
        // 监听路由变化
        created() {
            this.gettabs()
            this.user = JSON.parse(window.localStorage.getItem('user'))
            this.find_setting()
        },
        updated() {
            this.activeIndex = String(this.$store.state.id)
        },
        methods: {
            // 切换登录状态
            loginbtn(i) {
                console.log(i)
                this.login = i
            },
            // <!--发送验证码-->
            sendCode() {
                let tel = this.form.mobile
                if (this.login == 2) {
                    tel = this.pass.mobile
                }
                if (this.checkMobile(tel)) {
                    send_sms({
                        mobile: tel
                    }).then(res => {
                        let time = 60
                        this.buttonText = '已发送'
                        this.isDisabled = true
                        if (this.flag) {
                            this.flag = false;
                            let timer = setInterval(() => {
                                time--;
                                this.buttonText = time + ' 秒'
                                if (time === 0) {
                                    clearInterval(timer);
                                    this.buttonText = '重新获取'
                                    this.isDisabled = false
                                    this.flag = true;
                                }
                            }, 1000)
                        }
                    }).catch(err => {
                        this.$message({
                            message: err.msg,
                            type: 'warning'
                        });
                    })
                } else {
                    this.$message({
                        message: '请输入正确手机号',
                        type: 'warning'
                    });
                }
            },
            // 验证手机号
            checkMobile(str) {
                let re = /^1\d{10}$/
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            },
            // 获取tabs列表
            gettabs() {
                gettabs().then(res => {
                    this.tital_list.push(...res.data)
                }).catch()
            },
            // 重置密码
            reset() {
                if (this.checkMobile(this.pass.mobile)) {
                    reset_password(this.pass).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.pass = {
                            mobile: '',
                            card: '',
                            password: ''
                        }
                    }).catch(err => {
                        this.$message({
                            message: err.msg,
                            type: 'warning'
                        });
                    })
                } else {
                    this.$message({
                        message: '请输入正确手机号',
                        type: 'warning'
                    });
                }
            },
            // 注册
            register() {
                if (this.checkMobile(this.form.mobile)) {
                    member_register(this.form).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.form = {
                            mobile: '',
                            card: '',
                            password: ''
                        }
                    }).catch(err => {
                        this.$message({
                            message: err.msg,
                            type: 'warning'
                        });
                    })
                } else {
                    this.$message({
                        message: '请输入正确手机号',
                        type: 'warning'
                    });
                }
            },
            // 点击登录
            submit() {
                if (this.checkMobile(this.forms.mobile)) {
                    member_login(this.forms).then(res => {
                        if (res.code == 1) {
                            window.localStorage.setItem('user', JSON.stringify(res.data))
                            this.user = res.data
                            this.dialogVisible = false
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.form.mobile = ''
                            this.form.code = ''
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            });
                        }
                    }).catch(err => {
                        this.$message({
                            message: err.msg,
                            type: 'warning'
                        });
                    })
                } else {
                    this.$message({
                        message: '请输入正确手机号',
                        type: 'warning'
                    });
                }
            },
            // 点击退出登录
            logOut() {
                window.localStorage.removeItem('user')
                this.user = false
            },
            // 查询系统信息
            find_setting() {
                find_setting().then(res => {
                    this.setting = res.data
                })
            },
            // 点击弹出层关闭按钮
            handleClose(done) {
                this.dialogVisible = false
            },
            handleSelect(key, keyPath) {
                let url = '';
                switch (Number(key)) {
                    case 1:
                        url = '/home';
                        break;
                    case 23:
                        url = '/retrieve';
                        break;
                    case 24:
                        url = '/reserve';
                        break;
                    default:
                        url = '/recovery?id=' + key;
                        break;
                }
                this.$router.push(url)
                this.$store.commit('set_id', key)
                Bus.$emit('key', key);
            },
        },
    }
</script>
<style scoped="scoped">
    .home,
    .el-container {
        height: 100%;
    }

    .code button {
        margin-left: 20px;
        width: 140px;
        text-align: center;
    }

    .code>>>.el-form-item__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .el-header,
    .el-main {
        padding: 0 !important;
    }

    .el-header {
        background-color: #0670F5;
        align-items: center;
        min-width: 1400px;
    }

    .loginbox {
        float: right;
        margin: 14px 80px 0;
    }

    .login {
        display: inline-block;
        background-color: #FFFFFF;
        font-size: 14px;
        border-radius: 50px;
        padding: 6px 16px;
        color: #0670F5;
    }

    .login_title {
        display: flex;
        justify-content: space-around;
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 30px;
        cursor: pointer;
    }

    .box {
        position: fixed;
        right: 0;
        bottom: 25%;
        background-color: #FFF;
        padding: 4px 0 4px 4px;
        border-radius: 6px 0 0 6px;
    }

    .box:hover .box_left {
        display: block;
    }

    .box_left {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-101%, 0);
        background-color: #FFFFFF;
        border-radius: 4px;
        width: 220px;
        height: 250px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        display: none;
        padding: 10px;
    }

    .kefu {
        font-size: 14px;
        background-color: #0670F5;
        color: #FFFFFF;
        padding: 6px;
        border-radius: 6px 0 0 6px;
        text-align: center;
    }
</style>

<template>
    <div class="recovery">
        <!-- 选择卡类: -->
        <div class="cards">
            <div class="cards_tetol">
                选择卡类:
            </div>
            <div class="cards_body">
                <div class="cards_box" v-for="(item,index) in cards_list" @click="cards(item,index)">
                    <img :src="item.icon" class="imgs">
                    <img src="../assets/recovery/kalei-select.png" class="active" v-if="cardsIndex==index">
                </div>
            </div>
        </div>
        <!-- 选择卡种 -->
        <div class="cards">
            <div class="cards_tetol">
                选择卡种:
            </div>
            <div class="cards_body">
                <div class="cards_box_1" :class="speciesIndex==index?'hover':''" v-for="(item,index) in card_species"
                    @click="speciesbtn(item,index)">
                    <img :src="item.icon" class="img_1">
                    <div class="">
                        {{item.name}}
                    </div>
                    <img src="../assets/recovery/kazhong-select.png" class="active" v-if="speciesIndex==index">
                </div>
            </div>
        </div>
        <!-- 单卡面值: -->
        <div class="cards">
            <div class="cards_tetol">
                单卡面值:
            </div>
            <div class="cards_body">
                <div class="border_color cards_box_1 " :class="selectIndex==index?'hovers':''"
                    v-for="(item,index) in value_list" @click="selectBtn(item,index)">
                    <div class="">
                        ￥{{item.yprice}}
                    </div>
                    <div class="cards_price" style="font-size: 14px;">
                        <span style="color: #F4443C;">
                            ￥{{item.price}}
                        </span>
                        <span style="color: #8D8D8D;">
                            {{item.discount}}折
                        </span>
                    </div>
                    <img src="../assets/recovery/select.png" class="actives" v-if="selectIndex==index">
                </div>
            </div>
        </div>
        <!-- 选择卡类: -->
        <div class="cards">
            <div class="cards_tetol">
                提交方式:
            </div>
            <div class="cards_body">
                <div class="cards_box border_color" :class="singleIndex==index?'hover_1':''"
                    v-for="(item,index) in single_card" @click="singleBtn(item,index)">
                    <img :src="singleIndex==index?item.src1:item.src" style="width: 33px;height: 26px;">
                    <div class="" style="margin-left: 12px;font-size: 18px;">
                        {{item.text}}
                    </div>
                </div>
            </div>
        </div>
        <!-- 卡片信息: -->
        <div class="cards">
            <div class="cards_tetol">
                卡片信息:
            </div>
            <div class="" style="width: 100%;">
                <div class="information" v-if="singleIndex==0">
                    <div class="" style="margin: 0 auto;width: 40%;">
                        <el-form label-position="right" label-width="80px">
                            <el-form-item :label="card_species[speciesIndex].cardname">
                                <el-input v-model="number" :placeholder="'请输入'+card_species[speciesIndex].cardname"></el-input>
                            </el-form-item>
                            <el-form-item :label="card_species[speciesIndex].passname">
                                <el-input v-model="password" :placeholder="'请输入'+card_species[speciesIndex].cardname"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="information" v-else>
                    <div class="" style="margin: 0 auto;width: 80%;">
                        <el-input type="textarea" :rows="10"
                            placeholder="每张卡占用一行，卡号与密码之间用‘空格’隔开，输入完一行按‘回车键(Enter)’换行，因填写错误而导致失败后果自行承担。"
                            v-model="cardno">
                        </el-input>
                    </div>
                </div>
                <div class="radios">
                    <el-checkbox v-model="checked"></el-checkbox>
                    我已确认充值卡来源合法，如有问题，本人原因承担一切法律责任。我已阅读、理解并接受 <span style="color: #34ADFC;"
                        @click="dialogVisible = true">《{{newsdetail.title}}》</span>
                    <!-- 消息弹出层 -->
                    <el-dialog :title="newsdetail.title" :visible="dialogVisible" center width="50%"
                        :show-close='false'>
                        <div class="" v-html="newsdetail.post" style="overflow: hidden;"></div>
                        <span slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
                <div class="btn">
                    <el-button class="submitBtn" type="primary" @click='submitForm("ruleForm")'>确认提交</el-button>
                </div>
                <div class="stepBox">
                    <div class="step">
                        {{huishou.title}}
                    </div>
                    <div class="" v-html="value_list.length!=0?value_list[selectIndex].desc:''">
                        <!-- <div class="" style="margin-top: 10px;">
                            1.我要转让-电商礼品卡-选择面值-扫码提交-等待处理
                        </div>
                        <div class="" style="margin-top: 10px;">
                            2.推荐扫码提交流水号扫描条形码，密码扫描二维码
                        </div>
                        <div class="" style="margin-top: 10px;">
                            3.订单疑问可在账户中心-我的交易查看订单状态
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
    </div>
</template>

<script>
    import {
        goods_list,
        category_list,
        add_order,
        find_newsdetail
    } from '../api/home.js'
    import Bus from '../utils/bus.js'
    export default {
        data() {
            return {
                dialogVisible: false,
                cardsIndex: 0,
                speciesIndex: 0,
                selectIndex: 0,
                singleIndex: 0,
                checked: false,
                number: '',
                password: '',
                cards_list: [],
                card_species: [],
                value_list: [],
                single_card: [{
                        src: require('@/assets/recovery/card.png'),
                        src1: require('@/assets/recovery/card_hover.png'),
                        text: '单卡提交',
                    },
                    {
                        src: require('@/assets/recovery/cards.png'),
                        src1: require('@/assets/recovery/cards_hover.png'),
                        text: '批量提交'
                    }
                ],
                pid: 116,
                cate_id: 141,
                total_price: 0,
                real_price: 0,
                cardno: '',
                card_id: 0,
                newsdetail: {},
                huishou: {},
                id: 0
                
            }
        },
        created() {
            this.getCards()
            this.goods_list()
            this.find_newsdetail()
            this.step()
            if (this.$route.query.id) {
                this.id = this.$route.query.id
            }
        },
        mounted() {
            Bus.$on('key', (data) => {
                this.bus_data(data)
            });
        },
        methods: {
            // 
            bus_data(data) {
                switch (Number(data)) {
                    case 3:
                        this.cardsIndex = 2
                        break;
                    case 4:
                        this.cardsIndex = 4
                        break;
                    case 5:
                        this.cardsIndex = 0
                        break;
                    case 6:
                        this.cardsIndex = 3
                        break;
                    case 21:
                        this.cardsIndex = 1
                        break;
                    case 22:
                        this.cardsIndex = 5
                        break;
                    default:
                        break;
                }
                this.pid = this.cards_list[this.cardsIndex].id
                this.card_type()
            },
            // 提交
            submitForm(formName) {
                if (!window.localStorage.getItem('user')) return this.$message({
                    message: '请先点击右上角登录',
                    type: 'warning'
                });
                if (!JSON.parse(window.localStorage.getItem('user')).username) return this.$message({
                    message: '请先去实名认证',
                    type: 'warning'
                });
                if (this.singleIndex == 0) {
                    this.cardno = this.number + ' ' + this.password
                }
                if (!this.cardno || this.cardno == ' ') return this.$message({
                    message: '请先填需要回收的卡号跟密码！',
                    type: 'warning'
                });
                if (!this.checked) return this.$message({
                    message: '请阅读并勾选售卡转让协议！',
                    type: 'warning'
                });
                add_order({
                    user_id: JSON.parse(window.localStorage.getItem('user')).id,
                    username: JSON.parse(window.localStorage.getItem('user')).username,
                    mobile: JSON.parse(window.localStorage.getItem('user')).mobile,
                    card_id: this.cate_id,
                    total_price: this.total_price,
                    real_price: this.real_price,
                    cardno: this.cardno
                }).then(res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.number = ''
                    this.password = ''
                    this.cardno = ''
                }).catch(err=>{
                    this.$message({
                        message: err.msg,
                        type: 'error'
                    });
                })
            },
            // 选择提交方式
            singleBtn(item, index) {
                this.singleIndex = index
                this.number = ''
                this.password = ''
                this.cardno = ''
            },
            // 选择卡的面值
            selectBtn(item, index) {
                this.selectIndex = index
                this.total_price = item.yprice
                this.real_price = item.price
            },
            // 选择卡种
            speciesbtn(item, index) {
                this.speciesIndex = index
                this.cate_id = item.id
                this.selectIndex = 0
                this.goods_list()
            },
            // 选择卡类
            cards(item, index) {
                this.pid = item.id
                this.card_id = item.id
                this.cardsIndex = index
                var type = '';
                switch (Number(index)) {
                    case 0:
                        type = 5
                        break;
                    case 1:
                        type = 21
                        break;
                    case 2:
                        type = 3
                        break;
                    case 3:
                        type = 6
                        break;
                    case 4:
                        type = 4
                        break;
                    case 5:
                        type = 22
                        break;
                    default:
                        break;
                }
                this.$router.push('/recovery?id=' + type)
                this.$store.commit('set_id', type)
                this.speciesIndex = 0
                this.card_type()
            },
            // 获取面值
            goods_list() {
                goods_list({
                    cate_id: this.cate_id
                }).then(res => {
                    this.value_list = res.data.data
                    this.total_price = res.data.data[this.selectIndex].yprice
                    this.real_price = res.data.data[this.selectIndex].price
                })
            },
            // 获取卡种
            card_type() {
                category_list({
                    pid: this.pid
                }).then(res => {
                    this.card_species = res.data
                    this.cate_id = res.data[0].id
                    this.goods_list()
                })
            },
            // 获取卡类
            getCards() {
                category_list().then(res => {
                    this.cards_list = res.data
                    this.card_id = res.data[this.cardsIndex].id
                    this.bus_data(this.id)
                })
            },
            // 回收内容
            step() {
                find_newsdetail({
                    id: 28
                }).then(res => {
                    this.huishou = res.data
                })
            },
            // 获取协议内容
            find_newsdetail() {
                find_newsdetail({
                    id: 33
                }).then(res => {
                    this.newsdetail = res.data
                })
            }
        }
    }
</script>

<style scoped="scoped">
    .hover {
        border: 2px solid #3DA1F5 !important;
        color: #36B9FD !important;
        background-color: #F0F8FF
    }

    .hovers {
        border: 2px solid #F78360 !important;
        background-color: #FFEBEE
    }

    .hover_1 {
        border: 2px solid #81BEE3 !important;
        color: #34ADFC !important;
        background-color: #F0F8FF
    }

    .active {
        position: absolute;
        top: 0;
        right: 0;
        width: 33px;
        height: 33px;
    }

    .actives {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
    }

    .recovery {
        width: 1200px;
        padding: 60px 0;
        margin: 0 auto;
    }

    .cards {
        display: flex;
        justify-content: flex-start;
    }

    .cards_tetol {
        font-weight: 600;
        width: 70px;
        flex-shrink: 0;
    }

    .cards_body {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .border_color {
        border: 1px solid #81BEE3;
    }

    .cards_box {
        position: relative;
        margin-left: 25px;
        margin-bottom: 25px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 187px;
        height: 65px;
    }

    .imgs {
        width: 100%;
        height: 100%;
    }

    .cards_box_1 {
        position: relative;
        margin-left: 25px;
        margin-bottom: 25px;
        padding: 10px 20px;
        border-radius: 6px;
        border: 1px solid #F1F1F1;
        text-align: center;
        width: 187px;
        font-size: 14px;
        color: #8D8D8D;
    }

    .img_1 {
        width: 110px;
        height: 35px;
    }

    .cards_price {
        display: flex;
        justify-content: space-between;
    }

    .information {
        width: 100%;
        padding: 60px 0;
        background-color: #E7F2FF;
        margin-left: 20px;
    }

    .radios {
        margin: 20px;
        font-size: 14px;
        color: #787878;
    }

    a {
        text-decoration: none;
    }

    .btn {
        text-align: center;
    }

    .submitBtn {
        font-size: 18px;
        width: 325px;
        height: 60px;
    }

    .stepBox {
        font-size: 14px;
        margin-left: 20px;
        color: #575757;
    }

    .step {
        color: #3F9EFC;
        text-align: center;
        background-color: #E7F2FF;
        padding: 15px;
        margin: 26px 0;
    }
</style>
